import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";
import showdown from "showdown";

import MediaSoundcloud from "../components/MediaSoundcloud";
import MediaYoutube from "../components/MediaYoutube";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Content from "../components/Content";
import Image from "../components/Image";

import { videos, audios } from "../content/data/coros-unidos";
import Footer from "../components/Footer";
import { graphql } from "gatsby";
import Heading from "../components/Heading";
import SideBar from "../components/SideBar";

const converter = new showdown.Converter();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
`;

const BioContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Bio = styled.div``;

const bio = `El coro instrumental o coros unidos está compuesto por hermanos y hermanas que con alegría y contentamiento alaban al Señor tanto con sus voces como con sus instrumentos en cada uno de nuestros cultos.  

Coros Unidos no sólo se hace presente cantando hermosas alabanzas para nuestro Dios en cada reunión general y servicio especial que se realice en nuestro templo catedral, sino que también en cada una de nuestras clases tenemos la bendición de contar con un coro que alaba al Señor. Además este coro acompaña las predicaciones que se realizan en la calle. 

La jefatura del coro instrumental de nuestra iglesia está compuesta por nuestro hermano Jorge Cornejo como jefe de coro y por los hermanos Luis Acuña, Francisco Chamorro y Javier Cornejo como ayudantes. 

Los ensayos se realizan los días miércoles de cada semana a las 19:30 horas.`;

export default class CorosUnidosPage extends Component {
  constructor() {
    super();

    this.state = {
      filters: {
        video: true,
        audio: false
      }
    };
  }

  render() {
    const { data } = this.props;

    return (
      <Layout>
        <Seo title="Coros Unidos" keywords={[`iglesia`]} />
        <Container>
          <Header />
          <Content>
            <Inner>
              <Grid container spacing={40}>
                <Grid item xs={12} md={8}>
                  <Heading>Coros Unidos</Heading>
                  <Image fluid={data.profileImage.childImageSharp.fluid} />
                  <BioContainer>
                    <Heading>Sobre Nuestro Coro</Heading>
                    <Bio
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(bio)
                      }}
                    />
                  </BioContainer>
                  {/*<Heading>Multimedia</Heading>*/}
                  {/*<MediaMenu*/}
                  {/*  onChange={(filters) => this.setState({filters})}*/}
                  {/*/>*/}
                  {/*<Grid container>*/}
                  {/*  {*/}
                  {/*    this.renderVideos(this.state.filters.video)*/}
                  {/*  }*/}
                  {/*  {*/}
                  {/*    this.renderAudios(this.state.filters.audio)*/}
                  {/*  }*/}
                  {/*</Grid>*/}
                </Grid>
                <Grid item xs={12} md={4}>
                  <SideBar fixTop={180} fixBottom={140} />
                </Grid>
              </Grid>
            </Inner>
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }

  renderVideos(active) {
    const style = {};

    if (!active) {
      style.display = "none";
    }

    return (
      <Grid style={style} container spacing={40}>
        {videos.map(media => {
          switch (media.source) {
            case "youtube":
              return this.renderYoutube(media);
            default:
              return null;
          }
        })}
      </Grid>
    );
  }

  renderAudios(active) {
    const style = {};

    if (!active) {
      style.display = "none";
    }

    return (
      <Grid style={style} container spacing={40}>
        {audios.map(media => {
          switch (media.source) {
            case "soundcloud":
              return this.renderSoundcloud(media);
            default:
              return null;
          }
        })}
      </Grid>
    );
  }

  renderYoutube({ title, date, videoId, description }) {
    return (
      <Grid item xs={12}>
        <MediaYoutube
          title={title}
          date={date}
          videoId={videoId}
          description={description}
        />
      </Grid>
    );
  }

  renderSoundcloud({ title, date, url, description }) {
    return (
      <Grid item xs={12}>
        <MediaSoundcloud
          title={title}
          date={date}
          url={url}
          description={description}
        />
      </Grid>
    );
  }
}

export const query = graphql`
  query CorosUnidosPageQuery {
    profileImage: file(relativePath: { eq: "DSC07184.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
